import React, { Component } from 'react'
import { graphql } from 'gatsby'
import KCO from '../components/checkout/kco'
import SEO from '../components/seo'

import ImageHeadline from '../components/home/ImageHeadline';
import LocaleManager from '../components/LocaleManager';
import { FormattedMessage } from 'react-intl';

const Checkout = ({ data, pageContext: { locale } }) => (
    <LocaleManager locale={locale}>
        <FormattedMessage id="checkout.title">
          {translation => <SEO title={translation} lang={locale} /> }
        </FormattedMessage>
        <div className="content extra-top">
            <KCO locale={locale} />
        </div>
        <ImageHeadline gatsbyImage={ data.bottomBg.childImageSharp.fluid } />
    </LocaleManager>
)

export const query = graphql`
  query {
    bottomBg: file(relativePath: { eq: "about-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Checkout
