import React, { Component } from 'react';
import Img from 'gatsby-image';
import Context from '../Context';
import firebase from 'gatsby-plugin-firebase'

import minusIcon from '../../images/minus-icon-dark.svg';

import './orderTable.scss';
import { FormattedMessage } from 'react-intl';
import { getViewedItem } from '../../utilities/firebaseAnalytics';
import { getFormattedPrice, getPrice } from '../../utilities/currencies';

class OrderTable extends Component {
  getCartItems() {
    const { data: { cart } } = this.context;
    const sortedArray = [].concat(cart);
    const newCart = {};
    sortedArray.forEach((item) => {
      newCart[item.sku] = {
        amount: newCart[item.sku] ? newCart[item.sku].amount + 1 : 1,
        name: item.name,
        size: item.size,
        price: item.price,
        image: item.image,
        id: item.id,
      };
    });

    return newCart;
  }

  getUpdatedCart(sku) {
    const mutatedArray = this.context.data.cart.slice().reverse()
    const index = mutatedArray.findIndex(p => p.sku===sku)
    const removedArray = mutatedArray.splice(index, 1)
    return mutatedArray.reverse()
  }

  getCartTotal() {
    const cartItems = this.getCartItems();
    const total = Object.values(cartItems).reduce((a, b) => a + b.amount * getPrice(b.price, this.props.locale, this.props.currency), 0);
    return total;
  }

  render() {
    return (
      <div className="order-table-wrapper">
        <table className="cart-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="checkout.product" />
              </th>
              <th>
                <FormattedMessage id="checkout.size" />
              </th>
              <th>
                <FormattedMessage id="checkout.price" />
              </th>
              <th>
                <FormattedMessage id="checkout.quantity-short" />
              </th>
              <th>
                <FormattedMessage id="checkout.total" />
              </th>
            </tr>
          </thead>
          <tbody>
            { Object
              .keys(this.getCartItems()).length > 0 ? (Object.keys(this.getCartItems()).map((key) => (
                <tr key={key}>
                  <td>
                    <div className="image-name-wrapper">
                      <Img className="product-image" fixed={this.getCartItems()[key].image.fixed} />
                      <h4>
                        { this.getCartItems()[key].name }
                      </h4>
                    </div>
                  </td>
                  <td>
                    { this.getCartItems()[key].size }
                  </td>
                  <td>
                    { getFormattedPrice(getPrice(this.getCartItems()[key].price, this.props.locale, this.props.currency), this.props.locale, this.props.currency) }
                  </td>
                  <td>
                    { this.getCartItems()[key].amount }
                  </td>
                  <td>
                    <div className="total-wrapper">
                      <div className="total-text-wrapper">
                        { getFormattedPrice(getPrice(this.getCartItems()[key].price, this.props.locale, this.props.currency) * this.getCartItems()[key].amount, this.props.locale, this.props.currency) }
                      </div>
                      <Context.Consumer>
                        {({ data, set }) => (
                          <button className="remove-button" onClick={
                            () => {
                              console.log(getViewedItem(this.getCartItems()[key].name, this.getCartItems()[key].price, key))
                              firebase.analytics().logEvent(firebase.analytics.EventName.REMOVE_FROM_CART, getViewedItem(this.getCartItems()[key].name, this.getCartItems()[key].price, key))
                              set({ 
                                cart: this.getUpdatedCart(key)
                              })
                          }}><img src={minusIcon} alt="Remove" width="20" /></button>
                        )}
                      </Context.Consumer>
                    </div>
                  </td>
                </tr>
              ))) : (
                <tr>
                  <p>
                    <FormattedMessage id="checkout.empty" />
                  </p>
                </tr>
              )}
          </tbody>
        </table>
        <table className="total-table">
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="checkout.total" />
              </td>
              <td className="price-col">
                {getFormattedPrice(this.getCartTotal(), this.props.locale, this.props.currency)}
              </td>
            </tr>
            { this.props.discount.discount && this.props.discount.discount !== 0 && (
              <tr>
                <td>
                  <FormattedMessage id="checkout.total-after" /> {this.props.discount.discount * 100}<FormattedMessage id="checkout.percent-after" />
                </td>
                <td className="price-col">
                  {getFormattedPrice(Math.round(this.getCartTotal() * (1 - this.props.discount.discount) * 100) / 100, this.props.locale, this.props.currency)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

OrderTable.contextType = Context;

export default OrderTable;
